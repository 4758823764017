import { useCallback } from "react";
import { Box, Button, Stack, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon, Google as GoogleIcon } from "@mui/icons-material";

import { useStore, observer } from "../../../../service/mobx";
import { DiscordIcon } from "../../../Icon/Discord";

export default function useSignInDialog(cta = "Welcome back") {
  const { analytics, dialog } = useStore();
  const signInDialog = useCallback(() => {
    analytics.track.event("User Auth");
    dialog.configure({
      Content: () => <Content cta={cta} />,
      props: { fullWidth: true, maxWidth: "medium" },
      sx: {
        paper: {
          p: 0,
          width: undefined,
          maxHeight: undefined,
          bgcolor: "dark.surface.container.lowest"
        }
      }
    });
  }, [analytics, dialog, cta]);

  return signInDialog;
}
const Content = observer(function Content({ cta }) {
  const { dialog, device, user } = useStore();

  return (
    <Box
      textAlign="center"
      py={{ compact: 1, expanded: 3 }}
      px={{ compact: 1, expanded: 10 }}
    >
      {device.isPhone ? (
        <Stack pt={2} direction="row" justifyContent="flex-end">
          <IconButton onClick={dialog.toggle}>
            <CloseIcon sx={{ color: "dark.surface.on.color" }} />
          </IconButton>
        </Stack>
      ) : null}
      <Typography
        paragraph
        align="center"
        typography={{ compact: "headlineMd", expanded: "displaySm" }}
        color="dark.tertiary.color"
        sx={{ textWrap: "balance", py: 4 }}
      >
        {cta}
      </Typography>
      <Box
        p={3}
        bgcolor="dark.surface.container.low"
        sx={theme => ({ borderRadius: theme.shape.xl.round })}
      >
        <Typography
          pb={3}
          typography={{ compact: "headlineLg", expanded: "titleLg" }}
          color="dark.surface.on.color"
        >
          Sign in
        </Typography>
        <Box pt={3}>
          <Stack spacing={1}>
            {[
              {
                label: "Google",
                Icon: GoogleIcon,
                onClick: user.sign.in.google
              },
              {
                label: "Discord",
                Icon: DiscordIcon,
                onClick: user.sign.in.google
              }
            ].map(({ label, Icon, onClick }, index) => (
              <Button
                fullWidth
                key={index}
                variant="filled"
                onClick={() => {
                  onClick();
                  dialog?.set.open(false);
                }}
                startIcon={<Icon sx={{ ml: 1, width: 18, height: 18 }} />}
                sx={theme => ({
                  height: 48,
                  typography: "labelLg",
                  color: "dark.primary.color",
                  bgcolor: "dark.primary.on.color",
                  borderRadius: theme.shape.md.round,
                  transition: theme.transitions.create("all"),
                  "&:hover": {
                    bgcolor: "dark.primary.color",
                    color: "dark.primary.on.color"
                  }
                })}
              >
                {label}
              </Button>
            ))}
          </Stack>
        </Box>
      </Box>
      <Typography
        pt={4}
        component="div"
        variant="bodySm"
        align="center"
        color="dark.secondary.color"
        sx={{
          textWrap: "balance",
          "& a": {
            color: "inherit",
            textDecoration: "none"
          },
          "& a:hover": {
            textDecoration: "underline"
          }
        }}
      >
        By continuing you agree to the{" "}
        <a href="/legal/terms" target="_blank">
          terms of service
        </a>{" "}
        and{" "}
        <a href="/legal/privacy" target="_blank">
          privacy policy
        </a>
      </Typography>
    </Box>
  );
});
